import React, { useRef, useState } from 'react';

import useOutsideClick from '../../../../hooks/useOutsideClick';

import './CustomSelect.scss';

const CustomSelect = ({ options, selectedOption, setSelectedOption, defaultOption, icon, isPagination }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);
  const selectInput = useRef(null);

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  useOutsideClick(selectRef, selectInput, isOpen, () => setIsOpen(false));

  return (
    <div className={`custom-select ${isOpen ? 'opened' : ''} ${isPagination ? 'pagination' : ''}`} ref={selectRef}>
      <div ref={selectInput} className="custom-select__selected-option" onClick={() => setIsOpen(!isOpen)}>
        <span className={`custom-select__selected-option-name ${defaultOption && !selectedOption ? 'default' : ''}`}>
          {icon && (
            <div className="input-icon">
              <img src={icon} />
            </div>
          )}
          {defaultOption && !selectedOption
            ? defaultOption
            : options.find((option) => option.value == selectedOption).label}
        </span>
        <svg
          className={`arrow ${isOpen ? 'rotated' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M6 10L12 16L18 10" stroke="#000000" strokeLinecap="round" />
        </svg>
      </div>
      {isOpen && (
        <div className="custom-select__options-wrapper">
          <ul className="custom-select__options-list">
            {options.map((option, index) => (
              <li key={index} onClick={() => handleOptionSelect(option.value)}>
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
