import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useOutletContext } from 'react-router-dom';

import InputItem from '../Forms/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import Fader from '../Loaders/Fader';

import { setNotify } from '../../../datastore/actions/actionActions';
import useOutsideClick from '../../../hooks/useOutsideClick';

import searchIcon from '../../../assets/images/icons/search.svg';
import './TagList.scss';

const TagList = ({
  isOpen,
  taggingType,
  taggingList,
  startTaggingList,
  handleTagsCancel,
  activeItemId,
  handleGetItemsData,
  setIsTaggingActive,
  request,
}) => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const [taggingActiveLoading, setTaggingActiveLoading] = useState(false);
  const [activeTaggingList, setActiveTaggingList] = useState(startTaggingList);
  const [searchTerm, setSearchTerm] = useState('');

  const { setIsModalOpen } = useOutletContext();

  const windowRef = useRef();

  useEffect(() => {
    setIsModalOpen(isOpen);

    return () => {
      setIsModalOpen(false);
    };
  }, [isOpen]);

  const renderActiveItems = (itemObj) => {
    if (taggingType === 'districts') {
      if (activeTaggingList.length > 0 && itemObj.id === activeTaggingList[0].id) {
        setActiveTaggingList([]);
      } else {
        setActiveTaggingList([itemObj]);
      }
    } else {
      setActiveTaggingList((prevState) => {
        const newItems = [...prevState];
        const index = newItems.findIndex((elem) => elem.id === itemObj.id);
        if (index === -1) {
          newItems.push(itemObj);
        } else {
          newItems.splice(index, 1);
        }
        return newItems;
      });
    }
  };

  const onTagsCancelHandler = () => {
    handleTagsCancel();
    setActiveTaggingList([]);
  };

  const handleTaggingSubmit = async () => {
    setTaggingActiveLoading(true);

    const idList = activeTaggingList.filter((item) => item !== '+').map((item) => item.id);

    const res = await request(config, activeItemId, idList);

    if (res.status === 201) {
      handleGetItemsData();
      setIsTaggingActive(false);
      setTaggingActiveLoading(false);
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
    } else {
      console.log(
        `Error: ${taggingType === 'searches' ? 'Assigning Topics' : taggingType === 'groups' ? 'Assigning Search Groups' : taggingType === 'trends' ? 'Assigning Google Trend Topics' : taggingType === 'districts' ? 'Assigning District' : ''}`,
      );
    }
  };

  useOutsideClick(windowRef, null, isOpen, onTagsCancelHandler);

  const filteredTaggingList = taggingList.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          <div className="logo-large">
            {taggingType === 'searches'
              ? 'Tag Searches'
              : taggingType === 'groups'
                ? 'Tag Search Groups'
                : taggingType === 'trends'
                  ? 'Tag Google Trend Topics'
                  : taggingType === 'districts'
                    ? 'Select Electoral District'
                    : ''}
          </div>
          {!taggingActiveLoading ? (
            <>
              <InputItem
                label={`Search for ${taggingType}`}
                type="text"
                icon={searchIcon}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className={`tags n${filteredTaggingList.length}`}>
                <PerfectScrollbar
                  options={{
                    wheelPropagation: false,
                    autoHide: false,
                  }}
                >
                  {filteredTaggingList.length === 0 ? (
                    <span>No {taggingType}</span>
                  ) : (
                    filteredTaggingList.map((item) => (
                      <div
                        key={item.id}
                        className={`checkarea searcharea ${activeTaggingList.find((elem) => elem.id === item.id) ? 'checked' : ''}`}
                        onClick={() => renderActiveItems(item)}
                      >
                        <input
                          type={taggingType === 'districts' ? 'radio' : 'checkbox'}
                          value={item.name}
                          checked={
                            taggingType === 'districts'
                              ? activeTaggingList.length > 0 && activeTaggingList[0].id === item.id
                              : !!activeTaggingList.find((elem) => elem.id === item.id)
                          }
                        />
                        <label>{item.name}</label>
                      </div>
                    ))
                  )}
                </PerfectScrollbar>
              </div>
              <div className="submit delete">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onTagsCancelHandler}
                  text={'Save'}
                  onClick={handleTaggingSubmit}
                />
              </div>
            </>
          ) : (
            <Fader />
          )}
        </div>
      </div>
    </div>
  );
};

export default TagList;
