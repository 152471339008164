import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import GoogleAnalytics from './GoogleAnalytics';
import Header from '../app/components/Header/Header';
import Dashboard from '../app/views/Dashboard/Dashboard';
import Elections from '../app/views/Elections/Elections';
import Map from '../app/views/Map/Map';
import MarketPulse from '../app/views/MarketPulse/MarketPulse';
import Trends from '../app/views/Trends/Trends';
import ControlPanel from '../app/views/ControlPanel/ControlPanel';

import { getCustomState } from './LocalStorage';
import { USER_DATA, USER_ROLES } from './constants/AppConstants';

const AppRoutes = ({ sessionState }) => {
  const action = useSelector((state) => state.action);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginAttempt, setLoginAttempt] = useState(false);

  const userData = getCustomState(USER_DATA, true);
  const adminRoleValue = USER_ROLES.find((role) => role.short === 'Admin').value;

  useEffect(() => {
    if (action.loggingIn) {
      setLoginAttempt(true);
      setLoggingIn(true);
      return;
    }
    setLoginAttempt(false);
    setLoggingIn(false);
  }, [action]);

  return (
    <div className={loggingIn ? 'blur-frame' : !loginAttempt && !sessionState ? 'hidden' : ''}>
      <Router>
        <GoogleAnalytics />
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Dashboard />} />
            <Route path="election/:id" element={<Elections />} />
            <Route path="maps" element={<Map />} />
            <Route path="research" element={<MarketPulse />} />
            <Route path="trends" element={<Trends />} />
            {userData && userData.role === adminRoleValue && <Route path="control_panel" element={<ControlPanel />} />}
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
