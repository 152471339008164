import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Fader from '../Loaders/Fader';

import { setSession } from '../../../datastore/actions/sessionActions';
import { deleteCustomState } from '../../../util/LocalStorage';
import { SESSION_ID, SESSION_STATE, USER_DATA, USER_ROLES } from '../../../util/constants/AppConstants';

import FaqsIcon from '../../../assets/images/icons/faqs.svg';
import './UserMenu.scss';

const UserMenu = ({ profileRef, setIsOpen, userInfo, handleFaqPage }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    deleteCustomState(SESSION_STATE);
    deleteCustomState(SESSION_ID);
    deleteCustomState(USER_DATA);
    setIsOpen(false);
    dispatch(setSession(false));
    window.location.reload();
    navigate('/');
  };

  return (
    <div className="user-menu" ref={profileRef}>
      <div className="user-frame">
        <div className="username">
          {userInfo ? (
            <>
              {userInfo.firstname} {userInfo.lastname}{' '}
              <span>{USER_ROLES.find((role) => role.value == userInfo.role)?.short ?? ''}</span>
            </>
          ) : (
            <Fader />
          )}
        </div>
      </div>
      <div className="user-image">
        {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : '...'}
      </div>
      <div className="user-info">
        <span className="email">{userInfo ? userInfo.email : '...'}</span>
        <span className="org">{userInfo ? userInfo.job : '...'}</span>
      </div>
      <div className="menu-obj" onClick={handleFaqPage}>
        <div className="menu-icon">
          <img src={FaqsIcon} />
        </div>
        <div className="menu-label">Guides &amp; FAQs</div>
      </div>
      {/* @ Feature off (temp):
      <SwitchInput label={'Background'} onChangeHandler={handleChangeBackgroundStatus} inputprops={backgroundEffectFormInputProps} checkedStatus={backgroundEffectChecked} />
      */}
      <div className="logout">
        <span onClick={handleLogout}>Logout</span>
      </div>
    </div>
  );
};

export default UserMenu;
