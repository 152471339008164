import axios from 'axios';

import { setAxiosConfig } from '../util/SetDefaultLists';

const apiRequest = async (config, endpoint, method = 'get', data = null) => {
  try {
    const axiosConfig = setAxiosConfig(config, endpoint, method, data);
    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${endpoint}:`, error.message);
    return { error: error.message || 'Unexpected error occurred.' };
  }
};

const apiRequestDev = async (config, endpoint, method = 'get', data = null, upload = false) => {
  try {
    const axiosConfig = setAxiosConfig(config, endpoint, method, data, upload, true);
    const response = await axios(axiosConfig);
    return response;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${endpoint}:`, error.message);
    return { message: error.response.data.message || error.message || 'Unexpected error occurred.' };
  }
};

export const getAllUsers = async (config) => apiRequestDev(config, '/users/all');

export const getUsersForAdmin = async (config, id) => apiRequestDev(config, `/users/admin?id=${id}`);

export const getAdminPermissions = async (config, id) => apiRequestDev(config, `/users/permissions?id=${id}`);

export const getUserInfo = async (config, id) => apiRequest(config, `/users/info?id=${id}`);

export const saveUser = async (
  config,
  id,
  firstname,
  lastname,
  email,
  phone,
  jobTitle,
  city,
  locationState,
  country,
  userRole,
  password,
) => {
  return apiRequest(config, `/users/${id}/update`, 'post', {
    firstname,
    lastname,
    email,
    phone,
    job_title: jobTitle,
    location_city: city,
    location_state: locationState,
    location_country: country,
    role: userRole,
    password,
  });
};

export const createUser = async (
  config,
  firstname,
  lastname,
  email,
  phone,
  password,
  jobTitle,
  city,
  locationState,
  country,
  userRole,
) => {
  return apiRequest(config, '/users/create', 'post', {
    firstname,
    lastname,
    email,
    phone,
    password,
    job_title: jobTitle,
    location_city: city,
    location_state: locationState,
    location_country: country,
    role: userRole,
  });
};

export const linkUserAndAdmin = async (config, adminId, userId) => {
  return apiRequestDev(config, '/users/link', 'post', {
    admin_id: adminId,
    user_id: userId,
  });
};

export const deleteUser = async (config, id) => apiRequest(config, `/users/${id}/delete`, 'delete');

export const unlinkUserAndAdmin = async (config, userId) => {
  return apiRequestDev(config, '/users/unlink', 'delete', {
    user_id: userId,
  });
};

export const getTopics = async (config) => apiRequestDev(config, '/data/names');

export const assignTopics = async (config, userId, tags) => {
  return apiRequestDev(config, '/users/tags', 'post', {
    user_id: userId,
    tags,
  });
};

export const assignGroups = async (config, userId, groups) => {
  return apiRequestDev(config, '/users/groups', 'post', {
    user_id: userId,
    groups,
  });
};

export const assignTrends = async (config, userId, trends) => {
  return apiRequestDev(config, '/users/trends', 'post', {
    user_id: userId,
    trends,
  });
};

export const getAllDistricts = async (config) => apiRequestDev(config, '/districts/all');

export const assignDistrict = async (config, userId, districtId) => {
  return apiRequestDev(config, '/districts/user', 'post', {
    district_id: districtId[0],
    user_id: userId,
  });
};

export const setMP = async (config, userId, enabled) => {
  return apiRequestDev(config, '/users/marketpulse', 'patch', {
    user_id: userId,
    enabled,
  });
};
