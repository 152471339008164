import axios from 'axios';

import { setAxiosConfig } from '../util/SetDefaultLists';

const fetchData = async (config, endpoint, params) => {
  try {
    const url = `${endpoint}?${new URLSearchParams(params).toString()}`;
    const res = await axios(setAxiosConfig(config, url));
    return res.data;
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    return { error: err.message || 'Unexpected error' };
  }
};

const fetchDataDev = async (config, endpoint, params) => {
  try {
    const url = `${endpoint}?${new URLSearchParams(params).toString()}`;
    const res = await axios(setAxiosConfig(config, url, 'get', null, false, true));
    return res;
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    return { error: err.message || 'Unexpected error' };
  }
};

export const getEngagementsData = (config, id, user) => fetchData(config, '/data/engagements', { id, user });

export const getTopHashtags = (config, id, user) => fetchData(config, '/data/hashtags', { id, user });

export const getKeywords = (config, id, user) => fetchData(config, '/data/keywords', { id, user });

export const getMentionsData = (config, id, user) => fetchData(config, '/data/mentions', { id, user });

export const getTopNewsPosts = (config, id, user) =>
  fetchData(config, '/data/posts', { id, source: 1, latest: true, user_id: user });

export const getSentimentSources = (config, id, user) => fetchData(config, '/data/sentiment/source', { id, user });

export const getSentimentTrendData = (config, id, user) => fetchData(config, '/data/sentiment/trend', { id, user });

export const getTopSharedLinks = (config, id, user) => fetchData(config, '/data/links', { id, user });

export const getTopSocialPosts = (config, id, user, source) =>
  fetchData(config, '/data/posts', { id, source, latest: true, user_id: user });

export const getTopics = (config, id, user) => fetchData(config, '/data/list', { id, user_id: user });

export const getGroups = (config, userId) => fetchDataDev(config, '/groups/user', { user_id: userId });

export const getGroupTopics = (config, groupId) => fetchDataDev(config, '/groups/all_searches', { group_id: groupId });

export const getAllGroups = (config) => fetchDataDev(config, '/groups/all');

export const getDistrictData = (config, userId) => fetchDataDev(config, '/districts/user', { user_id: userId });

export const getSummary = (config, searchId) => fetchDataDev(config, '/data/summary', { search_id: searchId });

export const getTrends = (config, userId) =>
  fetchDataDev(config, '/trends/user', { user_id: userId, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });

export const getTrendByDate = (config, userId, trendId, date) =>
  fetchDataDev(config, '/trends/date', {
    user_id: userId,
    trend_id: trendId,
    date,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

export const getDailyTrends = (config, date) => {
  const params = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return fetchDataDev(config, '/trends/daily', date ? { date, ...params } : params);
};

export const getAllTrends = (config) => fetchDataDev(config, '/trends/all');

// temporarily
export const getMP = (config, userId) => fetchDataDev(config, '/users/marketpulse', { id: userId });
