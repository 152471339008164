import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import SentimentSource from '../../components/Sections/SentimentSource/SentimentSource';
import OverallSentiment from '../../components/Sections/OverallSentiment/OverallSentiment';
import SentimentTrend from '../../components/Sections/SentimentTrend/SentimentTrend';
import Mentions from '../../components/Sections/Mentions/Mentions';
import Engagements from '../../components/Sections/Engagements/Engagements';
import Hashtags from '../../components/Sections/Hashtags/Hashtags';
import SharedLinks from '../../components/Sections/SharedLinks/SharedLinks';
import Keywords from '../../components/Sections/Keywords/Keywords';
import TopSocialPosts from '../../components/Sections/TopSocialPosts/TopSocialPosts';
import NewsPosts from '../../components/Sections/NewsPosts/NewsPosts';
import Topics from '../../components/Topics/Topics';
import Summary from '../../components/Sections/Summary/Summary';
import Fader from '../../components/Loaders/Fader';
// import SwitchInput from '../../components/Forms/Switch';

import { BACKGROUND_EFFECT } from '../../../util/constants/AppConstants';
import { setBackgroundEffect } from '../../../datastore/actions/settingsActions';
import { saveCustomState } from '../../../util/LocalStorage';
import useOutsideClick from '../../../hooks/useOutsideClick';

import './Dashboard.scss';

const Dashboard = () => {
  const config = useSelector((state) => state.config.config);
  const menuRef = useRef();
  const menuCloseIcon = useRef();

  const dispatch = useDispatch();

  const { setFaqPage, userDataState } = useOutletContext();

  const backgroundEffectFormInputProps = { inputProps: { 'aria-label': 'Background Colour' } };
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);

  const [title, setTitle] = useState(null);

  const [topicsLoading, setTopicsLoading] = useState(true);

  const [sourceSentimentLoading, setSourceSentimentLoading] = useState(true);

  const [activeTopicId, setActiveTopicId] = useState(null);

  const [overallSentimentPositive, setOverallSentimentPositive] = useState(0);
  const [overallSentimentNegative, setOverallSentimentNegative] = useState(0);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // const handleGetTopPosts = (id, userId) => {
  //   axios(setAxiosConfig(config, `/data/posts?id=${id}&source=6&latest=true&user_id=${userId}`))
  //     .then((res) => {
  //       if (res.data.status === 'success') {
  //         setPostsLoading(false);
  //         setPostsData(res.data.data);
  //         setTopTwitterData(res.data.data);
  //       } else {
  //         console.log('ERROR: Grabbing Top Posts');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleChangeBackgroundStatus = (status) => {
    saveCustomState(BACKGROUND_EFFECT, status);
    dispatch(setBackgroundEffect(status));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const handleFaqPage = () => {
    setFaqPage((prevState) => !prevState);
  };

  /* useLayoutEffect(() => {
    const divAnimate = (dcRightDiv.current.getBoundingClientRect().top) + 10;
    const onScroll = () => {
    if (divAnimate < window.scrollY) {
        dcRightDiv.current.style.top = 0;
        dcRightDiv.current.className = 'dc-right fixed';
    } else {
        dcRightDiv.current.className = 'dc-right';
    }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []); */

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useOutsideClick(menuRef, menuCloseIcon, mobileMenuOpen, () => setMobileMenuOpen(false));

  return (
    <div className="platform-frame">
      <div ref={menuCloseIcon} className="dm-left mobile-menu">
        <span onClick={toggleMobileMenu} className={mobileMenuOpen ? 'open-menu' : ''}>
          <div></div>
          <div></div>
          <div></div>
        </span>
      </div>
      <Topics
        isOpen={mobileMenuOpen}
        setIsOpen={setMobileMenuOpen}
        menuRef={menuRef}
        setTitle={setTitle}
        activeTopicId={activeTopicId}
        setActiveTopicId={setActiveTopicId}
        userDataState={userDataState}
        loading={topicsLoading}
        setLoading={setTopicsLoading}
      />
      <div className="pf-right">
        <div className="pfr-header">
          <div className="dashboard-title">{title}</div>
        </div>
        {activeTopicId ? (
          <div className="dashboard-components">
            <div className="dc-left">
              <div className="dashboard-line">
                <Summary activeTopicId={activeTopicId} />
              </div>
              <div className="dashboard-line">
                <SentimentSource
                  handleFaqPage={handleFaqPage}
                  loading={sourceSentimentLoading}
                  setLoading={setSourceSentimentLoading}
                  activeTopicId={activeTopicId}
                  userDataState={userDataState}
                  setOverallSentimentNegative={setOverallSentimentNegative}
                  setOverallSentimentPositive={setOverallSentimentPositive}
                />
                <OverallSentiment
                  handleFaqPage={handleFaqPage}
                  loading={sourceSentimentLoading}
                  overallSentimentPositive={overallSentimentPositive}
                  overallSentimentNegative={overallSentimentNegative}
                />
              </div>
              <div className="dashboard-line">
                <SentimentTrend activeTopicId={activeTopicId} userDataState={userDataState} />
              </div>
              <div className="dashboard-line">
                <Mentions activeTopicId={activeTopicId} userDataState={userDataState} />
                <Engagements activeTopicId={activeTopicId} userDataState={userDataState} />
              </div>
              <div className="dashboard-line">
                <Hashtags activeTopicId={activeTopicId} userDataState={userDataState} />
              </div>
              <div className="dashboard-line">
                <SharedLinks activeTopicId={activeTopicId} userDataState={userDataState} />
                <Keywords activeTopicId={activeTopicId} userDataState={userDataState} />
              </div>
              <div className="dashboard-line">
                <TopSocialPosts activeTopicId={activeTopicId} userDataState={userDataState} />
                <NewsPosts activeTopicId={activeTopicId} userDataState={userDataState} />
              </div>
            </div>
          </div>
        ) : topicsLoading ? (
          <div className="content">
            <Fader />
          </div>
        ) : (
          <div className="content">
            <div className="no-data">You don't have any assigned Searches yet.</div>
          </div>
        )}
        <div className="platform-footer">
          <div className="version-num">{config.version}</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
