import React from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import ReactGA from 'react-ga4';

import PerfectScrollbar from 'react-perfect-scrollbar';
import TopicItem from '../Topics/TopicItem/TopicItem';
import Fader from '../Loaders/Fader';

import SearchIcon from '../../../assets/images/icons/search.svg';
import SearchIconWhite from '../../../assets/images/icons/search-white.svg';
import Logo from '../../../assets/images/logo.png';

const TrendsList = ({
  isOpen,
  setIsOpen,
  menuRef,
  setTitle,
  activeTopicId,
  setActiveTopicId,
  userDataState,
  showDailyTrends,
  setShowDailyTrends,
}) => {
  const config = useSelector((state) => state.config.config);

  const { trends, trendsLoading } = useOutletContext();

  const handleTopicSelection = (label, id) => {
    setShowDailyTrends(false);
    setTitle(label);
    setIsOpen(false);
    setActiveTopicId(id);
    ReactGA.event({
      category: 'trend_topic',
      action: 'click',
      label: `User ${userDataState.firstname} ${userDataState.lastname} viewed ${label}`,
      value: id,
    });
  };

  const handleDailyTrendsSelection = () => {
    setShowDailyTrends(true);
    setTitle('Daily trends');
    setIsOpen(false);
    setActiveTopicId(null);
    ReactGA.event({
      category: 'trend_topic',
      action: 'click',
      label: `User ${userDataState.firstname} ${userDataState.lastname} viewed Daily Trends`,
    });
  };

  return (
    <div className={`pf-left ${isOpen ? 'open' : ''}`} ref={menuRef}>
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className="menu-content">
        <TopicItem
          data={{ name: 'Daily trends', id: null }}
          topicHandler={handleDailyTrendsSelection}
          topicIcon={SearchIcon}
          topicIconWhite={SearchIconWhite}
          isActive={showDailyTrends}
        />
      </div>
      <div className="menu trends">
        {!trendsLoading && trends ? (
          <PerfectScrollbar
            options={{
              wheelPropagation: false,
              autoHide: false,
              wheelSpeed: 0.7,
            }}
          >
            <div className="menu-content">
              {trends.map((item) => (
                <TopicItem
                  key={item.id}
                  data={item}
                  topicHandler={handleTopicSelection}
                  activeTopic={activeTopicId}
                  topicIcon={SearchIcon}
                  topicIconWhite={SearchIconWhite}
                />
              ))}
            </div>
          </PerfectScrollbar>
        ) : !trendsLoading && !trends ? (
          <div className="no-data">No assigned topics</div>
        ) : (
          <div className="sentiment-charts">
            <Fader />
          </div>
        )}
      </div>
      <div className="menu-footer">
        <div className="disclaimer">&copy; {config.disclaimer}</div>
      </div>
    </div>
  );
};

export default TrendsList;
