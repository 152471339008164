import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import LineChartComponent from '../../Charts/LineChart';
import Fader from '../../Loaders/Fader';

import useOutsideClick from '../../../../hooks/useOutsideClick';

import TrendIcon from '../../../../assets/images/icons/trend.svg';
import './InterestOverTime.scss';

const InterestOverTime = ({ activeTopic, loading }) => {
  const [trendDivWidth, setTrendDivWidth] = useState(250);
  const [interestOverTimeData, setInterestOverTimeData] = useState([]);
  const [faq, setFaq] = useState(false);

  const { trends } = useOutletContext();

  const trendRef = useRef();
  const faqRef = useRef();
  const faqIconRef = useRef();

  useOutsideClick(faqRef, faqIconRef, faq, () => setFaq(false));

  const toggleFaq = () => {
    setFaq((prevState) => !prevState);
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      if (trendRef.current) {
        setTrendDivWidth(trendRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (trends && activeTopic) {
      setInterestOverTimeData(activeTopic.interest_over_time.sort((a, b) => a.date - b.date));
    }
  }, [trends, activeTopic]);

  return (
    <div className="interest chart-component">
      <div ref={faqIconRef} className="info-icon" onClick={toggleFaq}>
        i
      </div>
      {faq && (
        <div className="info-box" ref={faqRef}>
          <div className="ii-copy">
            <div className="iic-sub">Interest over time</div>
            <div className="iic-exp">
              Numbers represent search interest relative to the highest point on the chart for the given time. A value
              of 100 is the peak popularity for the term. A value of 50 means that the term is half as popular. A score
              of 0 means there was not enough data for this term.
            </div>
          </div>
        </div>
      )}
      <div ref={trendRef} className="chart-title align-left">
        <img src={TrendIcon} /> Interest over time
      </div>
      {!loading ? (
        interestOverTimeData.length ? (
          <LineChartComponent
            data={interestOverTimeData}
            width={trendDivWidth}
            xAxisData={interestOverTimeData}
            keyToLabel={{ value: 'Relative value of Interest' }}
            colorMap={{ value: '#376EBA' }}
            useXAxis={true}
            useArea={true}
            showTime={true}
          />
        ) : (
          <div className="no-data">No Data Available. Please Check Back Shortly.</div>
        )
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default InterestOverTime;
