import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import MPSwitcher from '../../components/Forms/MPSwitcher/MPSwitcher';
import UserProfile from '../../components/UserProfile/UserProfile';
import UserAdd from '../../components/UserAdd/UserAdd';
import Table from '../../components/Table/Table';
import TagList from '../../components/TagList/TagList';
import Fader from '../../components/Loaders/Fader';

import { USER_TABLE_HEADER } from '../../../util/constants/AppConstants';
import {
  assignDistrict,
  assignGroups,
  assignTopics,
  assignTrends,
  getAdminPermissions,
  getAllDistricts,
  getTopics,
  getUserInfo,
  getUsersForAdmin,
} from '../../../http/usersAPI';
import { getAllGroups, getAllTrends } from '../../../http/dataAPI';
import { setNotify } from '../../../datastore/actions/actionActions';

import PencilIcon from '../../../assets/images/icons/pencil.svg';
import './ControlPanel.scss';

const ControlPanel = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const { userDataState } = useOutletContext();

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [permissions, setPermissions] = useState(null);

  const [activeNameList, setActiveNameList] = useState([]);
  const [activeUserId, setActiveUserId] = useState(false);

  const [isAddUser, setIsAddUser] = useState(false);

  const [activeUserProfileData, setActiveUserProfileData] = useState(null);
  const [activeUserProfileLoading, setActiveUserProfileLoading] = useState(false);
  const [nameData, setNameData] = useState(false);

  const [isTaggingActive, setIsTaggingActive] = useState(false);
  const [taggingType, setTaggingType] = useState('');

  const [activeDistrict, setActiveDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);

  const [groups, setGroups] = useState([]);
  const [trends, setTrends] = useState([]);

  const userTableColumns = useMemo(() => USER_TABLE_HEADER, []);

  const addItem = (id, type, activeItem, setActiveItem) => {
    setTaggingType(type);
    setIsTaggingActive(true);
    setActiveItem(activeItem);
    setActiveUserId(id);
  };

  const handleUserItems = (items, userId, type, field) => {
    return items.map((item) => {
      if (item === '+') {
        return (
          <span className="search-label add" onClick={() => addItem(userId, type, items, setActiveNameList)}>
            +
          </span>
        );
      }
      return <span className="search-label">{item[field]}</span>;
    });
  };

  const handleRenderDistrict = (district, userId, editable) => {
    return (
      <>
        {district && <span className="search-label">{district.name}</span>}
        {editable ? (
          <span className="search-label add" onClick={() => addItem(userId, 'districts', district, setActiveDistrict)}>
            {district ? <img src={PencilIcon} /> : '+'}
          </span>
        ) : (
          ''
        )}
      </>
    );
  };

  const handleSetUserData = (userdata, permissions) => {
    const userArr = userdata.map(
      ({ id, firstname, lastname, email, searches, groups, trends, district, marketpulse }) => ({
        id,
        firstname,
        lastname,
        email,
        searches: handleUserItems(
          permissions.topics_permission ? [...searches, '+'] : searches,
          id,
          'searches',
          'search',
        ),
        groups: handleUserItems(permissions.groups_permission ? [...groups, '+'] : groups, id, 'groups', 'group'),
        trends: handleUserItems(permissions.trends_permission ? [...trends, '+'] : trends, id, 'trends', 'trend'),
        district: handleRenderDistrict(district, id, permissions.district_permission),
        marketpulse: <MPSwitcher enabled={marketpulse} userId={id} editable={permissions.mp_permission} />,
      }),
    );
    setUserData(userArr);
  };

  const handleGetPermissions = async () => {
    const res = await getAdminPermissions(config, userDataState.id);

    if (res.status !== 200) {
      console.log('ERROR: Grabbing Admin Permissions');
      return null;
    }

    return res.data.permissions;
  };

  const handleGetUsersData = async () => {
    const res = await getUsersForAdmin(config, userDataState.id);

    if (res.status === 200) {
      const permissions = await handleGetPermissions();
      if (permissions) {
        handleSetUserData(res.data.users, permissions);
        setPermissions(permissions);
        setDataPreviewLoading(false);
      }
    } else {
      console.log('ERROR: Grabbing Users');
    }
  };

  const handleGetNameData = async () => {
    const res = await getTopics(config);

    if (res.status === 200) {
      setNameData(res.data);
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  const handleGetGroupsData = async () => {
    const res = await getAllGroups(config);

    if (res.status === 200) {
      setGroups(res.data.groups);
    } else {
      console.log('ERROR: Grabbing Groups');
    }
  };

  const handleGetTrendsData = async () => {
    const res = await getAllTrends(config);

    if (res.status === 200) {
      setTrends(res.data.trends);
    } else {
      console.log('ERROR: Grabbing Google Trend Topics');
    }
  };

  const handleGetDistrictsData = async () => {
    const res = await getAllDistricts(config);

    if (res.status === 200) {
      setDistricts(res.data.districts);
      setDataPreviewLoading(false);
    } else {
      console.log('ERROR: Grabbing Districts');
    }
  };

  const handleUserClick = async (id) => {
    setActiveUserProfileLoading(true);

    const res = await getUserInfo(config, id);

    if (res.status === 'success') {
      setDataPreviewLoading(false);
      setActiveUserProfileData(res.data);
      setActiveUserProfileLoading(false);
    } else {
      console.log('ERROR: Grabbing User Info');
    }
  };

  const handleAddUser = () => {
    if (userData.length >= permissions.users_limit) {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: "You can't add more users under your management",
        }),
      );
    } else {
      setIsAddUser(true);
    }
  };

  const handleUserCreate = async () => {
    setIsAddUser(false);
    handleGetUsersData();
  };

  const handleUserUpdate = (id) => {
    handleGetUsersData();
    handleUserClick(id);
  };

  const handleDeleteSuccess = () => {
    setActiveUserProfileData(null);
    handleGetUsersData();
  };

  const handleTagsCancel = () => {
    setIsTaggingActive(false);
    setActiveUserId(null);
  };

  useEffect(() => {
    if (dataPreviewLoading && config.api && userDataState) {
      handleGetPermissions();
      handleGetUsersData();
      handleGetNameData();
      handleGetGroupsData();
      handleGetTrendsData();
      handleGetDistrictsData();
    }
  }, [dataPreviewLoading, config, userDataState]);

  return (
    <div className="platform-frame">
      <div className="elections control">
        <div className="pfr-header">
          <div className="dashboard-title">Users</div>
          <div className="sentiment-trend chart-component user-component">
            {activeUserProfileLoading ? (
              <Fader />
            ) : activeUserProfileData ? (
              <UserProfile
                data={activeUserProfileData}
                onSaveHandler={(id) => handleUserUpdate(id)}
                onDeleteHandler={handleDeleteSuccess}
              />
            ) : (
              'Please Select a User From the List Below'
            )}
          </div>

          <div className="sentiment-trend chart-component">
            <div className="user-manager">
              <div
                className={`edit-btn ${permissions && userData.length >= permissions.users_limit ? 'disabled' : ''}`}
                onClick={handleAddUser}
              >
                Add New
              </div>
            </div>

            {!dataPreviewLoading ? (
              <Table
                columns={userTableColumns}
                data={userData}
                clickHandler={handleUserClick}
                clickHandlerTriggers={['firstname-th', 'lastname-th', 'email-th']}
                searchLabel="Search for users"
              />
            ) : (
              <div className="sentiment-charts">
                <Fader />
              </div>
            )}
          </div>

          {isAddUser && (
            <UserAdd isOpen={isAddUser} onSaveHandler={handleUserCreate} onCloseHandler={() => setIsAddUser(false)} />
          )}
          {isTaggingActive && (
            <TagList
              isOpen={isTaggingActive}
              taggingType={taggingType}
              taggingList={
                taggingType === 'searches'
                  ? nameData
                  : taggingType === 'groups'
                    ? groups
                    : taggingType === 'trends'
                      ? trends
                      : districts
              }
              startTaggingList={taggingType === 'districts' ? (activeDistrict ? [activeDistrict] : []) : activeNameList}
              handleTagsCancel={handleTagsCancel}
              activeItemId={activeUserId}
              handleGetItemsData={handleGetUsersData}
              setIsTaggingActive={setIsTaggingActive}
              request={
                taggingType === 'searches'
                  ? assignTopics
                  : taggingType === 'groups'
                    ? assignGroups
                    : taggingType === 'trends'
                      ? assignTrends
                      : assignDistrict
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
