/**
 *  @ These are all the app specific constants we will be using:
 */
export const SESSION_STATE = 'SESSION_STATE';
export const SESSION_ID = 'SESSION_ID';
export const USER_DATA = 'USER_DATA';
export const BACKGROUND_EFFECT = 'BACKGROUND_EFFECT';

export const USER_ROLES = [
  {
    label: 'Super Administrator',
    short: 'Super Admin',
    value: 1,
  },
  {
    label: 'Administrator',
    short: 'Admin',
    value: 2,
  },
  {
    label: 'General User',
    short: 'General',
    value: 3,
  },
];

export const units = ['', 'K', 'M', 'B', 'T', 'Q'];

export const partyColors = [
  {
    party: 'Progressive Conservative',
    color: '#9999FF',
  },
  {
    party: 'New Democratic',
    color: '#F4A460',
  },
  {
    party: 'Liberal',
    color: '#EA6D6A',
  },
  {
    party: 'Green',
    color: '#99C955',
  },
  {
    party: 'New Blue',
    color: '#02478d',
  },
  {
    party: 'Ontario Party',
    color: '#112f53',
  },
  {
    party: 'Consensus Ontario',
    color: '#00BAF2',
  },
  {
    party: 'Independent',
    color: '#DCDCDC',
  },
];

export const USER_TABLE_HEADER = [
  {
    Header: 'First Name',
    accessor: 'firstname',
    class: 'firstname-th',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
    class: 'lastname-th',
  },
  {
    Header: 'Email',
    accessor: 'email',
    class: 'email-th',
  },
  {
    Header: 'Searches/Topics',
    accessor: 'searches',
    class: 'searches-th',
  },
  {
    Header: 'Search groups',
    accessor: 'groups',
    class: 'groups-th',
  },
  {
    Header: 'Google Trend topics',
    accessor: 'trends',
    class: 'trends-th',
  },
  {
    Header: 'Electoral district',
    accessor: 'district',
    class: 'district-th',
  },
  {
    Header: 'MarketPulse',
    accessor: 'marketpulse',
    class: 'marketpulse-th',
  },
];
