import React, { useRef } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import FormButton from '../../components/Forms/FormButton/FormButton';

import useOutsideClick from '../../../hooks/useOutsideClick';

import TopicIcon from '../../../assets/images/icons/topic.svg';
import './Faq.scss';

const FaqView = ({ isOpen, handleFaqPage }) => {
  const faqDiv = useRef();

  useOutsideClick(faqDiv, null, isOpen, handleFaqPage);

  return (
    <div className="login-wrap faqs">
      <div className="login-view">
        <div ref={faqDiv} className="login-box faq-box">
          <div className="modal-title">
            <img src={TopicIcon} />
            Guides &amp; FAQs
          </div>
          <div className="scroll-wrap">
            <PerfectScrollbar
              options={{
                wheelPropagation: false,
                autoHide: false,
                wheelSpeed: 0.7,
              }}
            >
              <div className="scroll-content">
                <div className="guide-title">Left Hand Column</div>
                <div className="guide-text">
                  Users can access the top curated searches. Each Tab will bring you to a custom dashboard pulling in
                  millions of data points
                </div>

                <div className="guide-title">Sentiment by Source</div>
                <div className="guide-text">
                  This feature analyzes the sentiment (positive, negative, neutral) of messages from different sources
                  or platforms (e.g., Twitter, Facebook, blogs).
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Navigate to the 'Sentiment by Source' section on the dashboard.</li>
                    <li>View the sentiment breakdown by source, displayed in a pie chart or bar graph.</li>
                    <li>Click on a source to drill down into specific sentiment details for that platform.</li>
                  </ol>
                </div>

                <div className="guide-title">Overall Sentiment</div>
                <div className="guide-text">
                  Definition: Overall Sentiment provides a cumulative sentiment rating from all sources, giving you a
                  big-picture view of how your brand or topic is perceived.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Look for the 'Overall Sentiment' section, often presented as a gauge or summary statistic.</li>
                    <li>Assess the overarching sentiment score or percentage.</li>
                    <li>Utilize this data to gauge public perception.</li>
                  </ol>
                </div>

                <div className="guide-title">Top Posts</div>
                <div className="guide-text">
                  This identifies the most popular or influential posts related to your brand or specified keywords
                  across various platforms.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Find the 'Top Posts' section, which may be sorted by engagement, reach, or sentiment.</li>
                    <li>Review the posts to understand content that resonates with your audience.</li>
                    <li>Click on individual posts for more detailed analysis or to engage directly with the post.</li>
                  </ol>
                </div>

                <div className="guide-title">Sentiment Trend</div>
                <div className="guide-text">
                  Tracks the change in sentiment over time, helping to identify trends, spikes, or drops in public
                  perception.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Go to the 'Sentiment Trend' graph.</li>
                    <li>Analyze the trend lines for positive, negative, and neutral sentiments.</li>
                    <li>Hover over points on the graph for date-specific sentiment data.</li>
                  </ol>
                </div>

                <div className="guide-title">Mentions</div>
                <div className="guide-text">
                  Counts and analyzes how many times your brand or keywords are mentioned across different media
                  channels.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Access the 'Mentions' dashboard.</li>
                    <li>View the volume of mentions over a selected time period.</li>
                    <li>Sort or filter mentions by source, sentiment, or other criteria.</li>
                  </ol>
                </div>

                <div className="guide-title">Engagement</div>
                <div className="guide-text">
                  Measures the level of interaction with your content, including likes, shares, comments, and overall
                  activity.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Locate the 'Engagement' metric on your dashboard.</li>
                    <li>Examine various engagement metrics to see what content is driving interaction.</li>
                    <li>Use the data to inform content strategy and engagement tactics.</li>
                  </ol>
                </div>

                <div className="guide-title">Top Hashtags</div>
                <div className="guide-text">
                  Identifies the most frequently used hashtags in relation to your brand or chosen keywords.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Check the 'Top Hashtags' list.</li>
                    <li>Analyze which hashtags are most associated with your Topic.</li>
                    <li>Consider using these hashtags in your own posts to increase reach.</li>
                  </ol>
                </div>

                <div className="guide-title">Top Keyword Sentiment</div>
                <div className="guide-text">
                  Displays sentiment analysis for the most frequently occurring keywords in your dataset.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>View the 'Top Keyword Sentiment' chart.</li>
                    <li>Identify which keywords are most positively or negatively associated with your brand.</li>
                    <li>Use this insight to adjust your communication and SEO strategy.</li>
                  </ol>
                </div>

                <div className="guide-title">Top Shared Links</div>
                <div className="guide-text">
                  Shows the most shared URLs in posts about your brand, indicating the spread of content across the web.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Refer to the 'Top Shared Links' section.</li>
                    <li>Evaluate which content is being shared the most.</li>
                    <li>Consider reaching out to these sources for potential collaborations.</li>
                  </ol>
                </div>

                <div className="guide-title">Top Keywords</div>
                <div className="guide-text">
                  Lists the most common words or phrases associated with your brand or campaign.
                  <span className="sub-title">How to Use:</span>
                  <ol>
                    <li>Find the 'Top Keywords' list or cloud on your dashboard.</li>
                    <li>Use this list to understand what topics are currently associated with your topic.</li>
                    <li>Leverage popular keywords in your marketing campaigns for better SEO and relevance.</li>
                  </ol>
                </div>
              </div>
            </PerfectScrollbar>
          </div>

          <div className="submit faq">
            <FormButton text={'Done'} onClick={handleFaqPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqView;
